import React from "react";
import { graphql, Link } from "gatsby";
import { Container } from "react-bootstrap";

import Layout from "../components/layout";
import CategoryCards from "../components/category-cards";
import ArticleCards from "../components/article-cards";
import ProductCards from "../components/product-cards";
import CategorySlider from "../components/category-slider";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceWidth: 0,
    };
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      this.setState({ deviceWidth: window.innerWidth });
    }
  }
  render() {
    const isSmallScreen = this.state.deviceWidth <= 768;
    const articles = this.props.data.articles.edges.map(
      (n) => n.node.frontmatter,
    );
    const categories = this.props.data.categories.edges.map(
      (n) => n.node.frontmatter,
    );
    const products = this.props.data.products.edges.map(
      (n) => n.node.frontmatter,
    );
    return (
      <Layout>
        <Container>
          <section id="welcome">
            <Container className={`mt-5`}>
              <p style={{ fontSize: 24 }}>
                BusinessPro provides information on small business products and
                services. From ratings & reviews to top 10 lists, integrations,
                and alternatives, we’ve got you covered :)
              </p>
            </Container>
          </section>
          <hr xs="12" className={`mt-5`} />

          <section id="products" className={`mt-5`}>
            <Container>
              <h3>Products</h3>
              <ProductCards className={`mt-3`} items={products} />
            </Container>
          </section>

          <section
            id="categories-small"
            className="mt-5"
            hidden={!isSmallScreen}
          >
            <Container>
              <h3>Categories</h3>
              <div className="mt-3">
                <CategorySlider items={categories} />
              </div>
            </Container>
            <Container className={`text-center mt-5`}>
              <Link to="/categories" hidden>
                <h4>
                  <u>View all Categories</u>
                </h4>
              </Link>
            </Container>
          </section>

          <section
            id="categories-large"
            className={`mt-5`}
            hidden={isSmallScreen}
          >
            <Container>
              <h3>Categories</h3>
              <CategoryCards
                className={`mt-3 d-flex align-content-center`}
                items={categories}
              />
            </Container>
            <Container className={`text-center mt-4`}>
              <Link to="/categories" hidden>
                <h4>
                  <u>View all Categories</u>
                </h4>
              </Link>
            </Container>
          </section>

          <section id="articles" className={`mt-5`}>
            <Container>
              <h3>Articles</h3>
              <ArticleCards className={`mt-3`} items={articles} />
            </Container>
          </section>

          <section id="top-10" className={`mt-5`} hidden>
            <Container>
              <h3>Top 10</h3>
              <ProductCards className={`mt-3`} items={products} />
            </Container>
          </section>

          <section id="integrations" className={`mt-5`} hidden>
            <Container>
              <h3>Integrations</h3>
              <ProductCards className={`mt-3`} items={products} />
            </Container>
          </section>

          <section id="alternatives" className={`mt-5`} hidden>
            <Container>
              <h3>Alternatives</h3>
              <ProductCards className={`mt-3`} items={products} />
            </Container>
          </section>
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    articles: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(posts)/.*.md$/" }
        frontmatter: { template: { eq: "post" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            featured_image {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 174) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            description
            date
          }
        }
      }
    }
    categories: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(categories)/.*.md$/" }
        frontmatter: { template: { eq: "category" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            icon
          }
        }
      }
    }
    products: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/(products)/.*.md$/" }
        frontmatter: { template: { eq: "product" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 9
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            logo {
              childImageSharp {
                fixed(width: 160, height: 160) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Main;
