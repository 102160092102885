import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import styles from "./category-item.module.scss";

const CategoryItem = ({ className, icon, title, url }) => {
  return (
    <Link to={`/categories/${url}`} style={{ textDecoration: "none" }}>
      <div className={`${styles.category} ${className} text-center`}>
        <div className={`content`}>
          <i className={`material-icons`}>{icon}</i>
          <h6>{title}</h6>
        </div>
      </div>
    </Link>
  );
};

CategoryItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
};

CategoryItem.defaultProps = {
  className: "",
  icon: "face",
  title: "Category",
  url: "/category",
};

export default CategoryItem;
