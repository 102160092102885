import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Card, CardDeck } from "react-bootstrap";
import Img from "gatsby-image";

import styles from "./product-card.module.scss";

const ProductCard = ({ className, image, title, url }) => {
  return (
    <Card className={className}>
      <Link to={`/${url}`}>
        <Card.Body className={`pt-0`}>
          <Card.Title>{title}</Card.Title>
          <Img
            loading="eager"
            fixed={image.childImageSharp.fixed}
            placeholderStyle={{ visibility: "hidden" }}
          />
        </Card.Body>
      </Link>
    </Card>
  );
};

class ProductCards extends React.Component {
  render() {
    const { items, className } = this.props;

    const buildItem = (item, key) => (
      <ProductCard
        className={`pt-3 ${styles.product}`}
        key={key}
        image={item.logo}
        title={item.title}
        url={item.slug}
      />
    );

    return (
      <CardDeck className={`${className} text-center`}>
        {items && items.map((item, index) => buildItem(item, index))}
      </CardDeck>
    );
  }
}

ProductCards.propTypes = {
  items: PropTypes.array,
};

ProductCards.defaultProps = {
  items: [],
};

export default ProductCards;
