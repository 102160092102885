import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import CategoryItem from "../components/category-item";

class CategoryCards extends React.Component {
  render() {
    const { items, className } = this.props;

    const buildItem = (item, key) => (
      <Col
        xs={12}
        lg={2}
        md={3}
        key={key}
        className={`d-flex justify-content-center`}
      >
        <CategoryItem icon={item.icon} title={item.title} url={item.slug} />
      </Col>
    );

    return (
      <Row className={className}>
        {items && items.map((item, index) => buildItem(item, index))}
      </Row>
    );
  }
}

CategoryCards.propTypes = {
  items: PropTypes.array,
};

CategoryCards.defaultProps = {
  items: [],
};

export default CategoryCards;
