import React from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";

import CategoryItem from "../components/category-item";

class CategorySlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItemIndex: 0,
    };
  }
  render() {
    const { items } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            /* initialSlide: 2 */
          },
        },
      ],
    };
    return (
      <Slider {...settings}>
        {items &&
          items.map((item, index) => (
            <div className="pr-2 pl-2" key={index}>
              <CategoryItem
                title={item.title}
                icon={item.icon}
                url={item.slug}
                className=""
              />
            </div>
          ))}
      </Slider>
    );
  }
}

CategorySlider.propTypes = {
  items: PropTypes.array,
};

CategorySlider.defaultProps = {
  items: [],
};

export default CategorySlider;
